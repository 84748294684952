<template lang="html">
  <v-container fluid>
    <lock v-if="currentDay != 6 && currentDay != 0" @status="bukaAktivitas"></lock>
    <!-- tampilan utama -->
    <template v-if="open || currentDay == 6 || currentDay == 0">
      <v-row>
        <v-col md="12" lg="8">
          <base-material-card icon="mdi-calendar">
          <calendar ref="customCalendar" :batasTanggal="batasTanggal" @on-select-date="setSelectedDate"></calendar>
          </base-material-card>
        </v-col>

        <v-col lg="4">
          <base-material-card icon="mdi-file-chart">
            <v-tabs
              fixed-tabs
              v-model="tab"
              background-color="primary"
              dark
            >
              <v-tab>
                Aktivitas Utama
              </v-tab>
              <v-tab>
                Tambahan
              </v-tab>
            </v-tabs>

            <v-sheet max-height="550">
              <div v-if="showTab">
                <!-- <v-text-field :value="cari" @change="cari = $event" label="Cari Aktivitas" append-icon="mdi-magnify" hide-details></v-text-field> -->
                <v-text-field v-model="cari" label="Cari Aktivitas" append-icon="mdi-magnify" hide-details></v-text-field>

                <TabAktivitas :filtered='filteredData' @callDialog='openDialog'/>
              </div>
              <div v-else class="d-flex align-center flex-column">
                <v-img :src='"@/assets/mangbagja_sedih.jpg"' height="200" contain class="my-5"></v-img>
                <p class="paragraph">Anda tidak bisa input pada tanggal ini</p>
              </div>
            </v-sheet>
          </base-material-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h1 class="display-1 orange--text text--darken-2" >Laporan Aktivitas Utama</h1>
          <Laporan :laporan="laporanUtama" :loading="loadingUtama" @callDialog='openDialog' @update-laporan="getAktivitasTampil('kinerja')"/>
        </v-col>

        <v-col>
          <h1 class="display-1 orange--text text--darken-2">Laporan Aktivitas Tambahan</h1>
          <Laporan :laporan="laporanTambahan" :loading="loadingTambahan" @callDialog='openDialog' @update-laporan="getAktivitasTampil('umum')"/>
        </v-col>
      </v-row>

      <v-fab-transition>
        <v-btn
          v-model="fab"
          v-scroll="onScroll"
          fab
          large
          dark
          bottom
          right
          fixed
          class="primary"
          @click="onUpDownScroll"
          >
          <!-- <v-icon v-if="!fab">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon> -->
          <v-icon :class="{active:fab}">mdi-chevron-down</v-icon>
        </v-btn>

      </v-fab-transition>
    </template>


    <v-dialog v-model="dialog" max-width="500px">
      <v-card :loading="loadingForm">
        <v-card-title class="display-1 orange--text">Laporan Aktivitas {{selectedDate|formatTime}}</v-card-title>
        <v-card-text>

          <v-form ref="form" lazy-validation v-model="valid">
            <h1 class="display-1">{{form.nama_aktifitas}}</h1>
            <p class="paragraph mt-2">{{form.penjelasan}}</p>
            <v-select
              :items="form.kegiatan"
              item-text="nama"
              item-value="id"
              v-model="form.sasaran_kinerja_id"
              @change="showIndikator(form.sasaran_kinerja_id)"
              label="Sasaran Kinerja Pegawai"
            ></v-select>
            <h1 v-if="indikator_kuantitas != '' && indikator_kuantitas != null" class="display-1">Indikator Kuantitas</h1>
            <p v-if="showIndikator_ == true" class="paragraph mt-2">{{indikator_kuantitas}}</p>
            <h1 v-if="indikator_kualitas != '' && indikator_kualitas != null" class="display-1">Indikator Kualitas</h1>
            <p v-if="showIndikator_ == true" class="paragraph mt-2">{{indikator_kualitas}}</p>
            <h1 v-if="indikator_waktu != '' && indikator_waktu != null" class="display-1">Indikator Waktu</h1>
            <p v-if="showIndikator_ == true" class="paragraph mt-2">{{indikator_waktu}}</p>
            <v-row align="center">
              <v-col cols="3">
                <v-text-field
                label="Hasil"
                v-model="form.volume"
                :rules="[rules.required, rules.integerOnly]"
                width="10px"
                ></v-text-field>
              </v-col>
              <v-col cols="9">
                <span>x {{form.satuan_output}}</span>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col>Waktu</v-col>
              <v-col>
                <v-select
                :items="jam"
                v-model="form.jam_mulai"
                width="10px"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                :items="menit"
                v-model="form.menit_mulai"
                width="10px"
                ></v-select>
              </v-col>
              <v-col>
                sampai
              </v-col>
              <v-col>
                <v-select
                :items="jam"
                v-model="form.jam_selesai"
                width="10px"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                :items="menit"
                v-model="form.menit_selesai"
                width="10px"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="form.rumpun_id == 13">
              <h1 v-if="form.angka_kredit != '' && form.angka_kredit != null" class="display-1">Angka Kredit</h1>
              <p class="paragraph mt-2">{{form.angka_kredit}}</p>
            </v-row>
            <v-row v-else>
              <h1 v-if="form.angka_kredit == '' && form.angka_kredit == null" class="display-1">Menit</h1>
              <p v-if="form.angka_kredit == '' && form.angka_kredit == null" class="paragraph mt-2">{{form.waktu}}</p>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea counter="300" label="Keterangan" @copy.prevent @paste="onPasteKeterangan" @paste.prevent @cut.prevent @drag.prevent @drop.prevent v-model="form.keterangan" hint="Isilah dengan singkat, padat, dan jelas (max 300 karakter)"></v-textarea>
              </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-btn @click="dialog=false">
                  Batal
                </v-btn>
                <v-btn :loading="loadingBtn" @click='submit' class="success" :disabled="!valid">
                  {{form.button}}
                </v-btn>
            </v-row>
          </v-form>

        </v-card-text>
      </v-card>
    </v-dialog>
    <div id="bottom"></div>
  </v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import moment from 'moment'
import PelaksanaService from '@/services/PelaksanaService'
import TabAktivitas from './components/TabAktivitas'
import Laporan from './components/Laporan'
import Calendar from './components/Calendar'
import Lock from './components/Lock'

export default {
  components:{
    TabAktivitas,
    Laporan,
    Calendar,
    Lock
  },

  data: () => ({
    fab:false,
    valid:true,
    tab: '',
    timer:null,
    timeLeft:null,
    open:false,
    batasTanggal: '',
    customEvents: {},
    selectedDate: '',
    loadingBtn:false,
    loadingForm:false,
    itemsAU:[],
    itemsTmb:[],
    cari:'',
    test:'',
    aktivitas:{},
    dialog:false,
    startDate:'',
    endDate:'',
    form:{
      nama_aktifitas:'',
      penjelasan:'',
      sasaran_kinerja_id:'',
      volume:'',
      jam_mulai:7,
      menit_mulai:0,
      jam_selesai:7,
      menit_selesai:0,
      keterangan:'',
      satuan_output:'',
      angka_kredit:null,
      level:'',
      rumpun_id:null
    },
    rules:{
      required : value => !! value || 'tidak boleh kosong',
      integerOnly: v => /\d+/.test(v) || 'hanya boleh angka'
    },

    laporanUtama:[],
    loadingUtama:false,
    laporanTambahan:[],
    loadingTambahan:false,
    showIndikator_:false,
    indikator_kuantitas: '',
    indikator_kualitas: '',
    indikator_waktu: '',

    items: ['a', 'b'],
    ketLingkaran:[
      {'text':'Jumlah disetujui', color:'success'},
      {'text':'Jumlah ditolak', color:'error'},
      {'text':'Jumlah aktivitas utama masih diproses', color:'info'},
      {'text':'Jumlah aktivitas tambahan masih diproses', color:'brown'},
      // {'text':'Penunjukan aktivitas kerja', color:'warning'},
      // {'text':'Penunjukan target kerja', color:'purple'},
    ]
  }),

  watch:{
    selectedDate(val){
      this.getAktivitasTampil('kinerja')
      this.getAktivitasTampil('umum')
    },

    dialog(val){
      val || this.closeDialog()
    }
  },

  filters:{
    addZero:function(value){
      if (value*1 < 10){
        return '0'+value
      }
      return value
    },

    formatTime: function(value){
      moment.locale('id')
      return value ? moment(value).format('dddd, Do MMMM YYYY') : moment().format('dddd, Do MMMM YYYY');
    },
  },

  computed:{
    currentDay(){
      return moment().day()
    },

    siteSettings(){
      let open = moment()
      open.set('hour',17)
      open.set('minute',0)

      let close = moment()
      close.add(1,'days')
      close.set('hour',7)
      close.set('minute',0)

      console.log(open,close)
      return store.state.settings.site
    },

    currentDate(){
      return this.selectedDate ? this.selectedDate : moment().format('YYYY-MM-DD');
    },

    jam(){
      return _.range(24)
    },

    menit(){
      return _.range(0,60,5)
    },

    filteredData(){
      if(this.tab == 0){
        return this.filteredAU
      }
      return this.filteredTmb
    },

    filteredAU(){
      if (this.cari.length > 2){
        const reg = new RegExp(this.cari.toLowerCase(), 'i');
        return this.itemsAU.filter(x =>reg.test(x.nama_aktifitas))
      }
      return this.itemsAU
    },

    filteredTmb(){
      if (this.cari.length > 2){
        const reg = new RegExp(this.cari.toLowerCase(), 'i');
        return this.itemsTmb.filter(x =>reg.test(x.nama_aktifitas))
      }
      return this.itemsTmb
    },

    showTab(){
      let date = this.selectedDate
      return this.compareDate(date, null) == -1 && this.compareDate(date, this.batasTanggal) == 1 || this.compareDate(date, this.batasTanggal) == 0 || this.compareDate(date, null) == 0
    }
  },

  created() {
    PelaksanaService.getTanggalInputAktivitas().then(response => {
      this.batasTanggal = response.data.hmin7
    })

    this.getAktivitasUtama()
    this.getAktivitasTambahan()

    this.getAktivitasTampil('kinerja')
    this.getAktivitasTampil('umum')
  },

  // mounted(){
  //   let siteSettings = store.state.settings.site
  //   let open = moment(siteSettings.waktu_buka,'HH:mm')
  //   let close = moment(siteSettings.waktu_tutup,'HH:mm').add(1,'d')
  //   console.log(open,close)
  //   this.timer = setInterval(()=>{
  //     this.timeLeft = close - moment()
  //   },1000)
  // },

  methods: {
    showIndikator(e){
      PelaksanaService.getDetailSKP(e).then(response=>{
        this.loadingBtn = false
        let data = response.data
        if (data.status == 200){
          this.showIndikator_ = true
          if(typeof data['data'][0].indikator_kuantitas != 'undefined'){
            this.indikator_kuantitas = data['data'][0].indikator_kuantitas
          }
          if(typeof data['data'][0].indikator_kualitas != 'undefined'){
            this.indikator_kualitas = data['data'][0].indikator_kualitas
          }
          if(typeof data['data'][0].indikator_waktu != 'undefined'){
            this.indikator_waktu = data['data'][0].indikator_waktu
          }
        }else{
          store.commit('snackbar/setSnack',{message:data.error, color:'error'})
        }
      }).catch(error=>{
        this.loadingBtn=false
      })
    },
    bukaAktivitas(val){
      console.log(val, this.open)
      if (val == 'open'){
        this.open = true
      } else {
        this.open = false
      }
    },

    setSelectedDate(val){
      this.selectedDate = val
    },

    onScroll(e){
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },

    onUpDownScroll(){
      if (!this.fab){
        this.$vuetify.goTo('#bottom',{easing:'easeInQuad',duration:1000})
      } else {
        this.$vuetify.goTo(0,{easing:'easeInQuad',duration:1000})
      }
    },

    submit(){
      if (this.$refs.form.validate()){

        if(this.form.kategori === 'UTAMA' || this.form.jenis === 'kinerja'){
          this.form.id_input=this.form.id? this.form.id : -1
          this.loadingBtn = true
          PelaksanaService.laporSimpanAktivitas(this.form).then(response=>{
            this.loadingBtn = false
            let data = response.data
            if (data.success){
              if(data.error != undefined){
                let error = JSON.parse(data.error)
                store.commit('snackbar/setSnack',{message:error.data[0].error, color:'warning'})
                this.loadingBtn=false
              }else{
                store.commit('snackbar/setSnack',{message:'Laporan berhasil disimpan', color:'success'})
                // tutup dialog
                this.dialog=false
                this.$refs.customCalendar.getCalendar()
                this.getAktivitasTampil('kinerja')
              }
            }else{
              store.commit('snackbar/setSnack',{message:data.error, color:'error'})
            }
          }).catch(error=>{
            this.loadingBtn=false
          })

        }else if(this.form.kategori === 'UMUM' || this.form.jenis === 'umum' ){
          this.form.id_input=this.form.id? this.form.id : -1
          this.loadingBtn = true
          console.log('umum',this.form)

          PelaksanaService.laporSimpanAktivitasTambahan(this.form).then(response=>{
            this.loadingBtn = false
            let data = response.data
            if (data.success){
              store.commit('snackbar/setSnack',{message:'Laporan berhasil disimpan', color:'success'})

              // tutup dialog
              this.dialog=false
              this.$refs.customCalendar.getCalendar()
              this.getAktivitasTampil('umum')
            }else{
              store.commit('snackbar/setSnack',{message:data.error, color:'error'})
            }
          }).catch(error=>{
            this.loadingBtn=false
          })
        }
      }else {
        this.loadingBtn = false
      }
    },

    openDialog(item){

      console.log(item.id)
      console.log(item)
      this.loadingForm = true
      this.form.kode_aktivitas = item.kode_aktifitas
      this.form.tanggal = this.currentDate
      if(item.kategori == undefined){
        item.kategori = item.jenis == 'kinerja' ? 'UTAMA' : 'UMUM'
      }
      
      PelaksanaService.getLaporAktivitasKegiatan(item.kode_aktifitas, item.kategori).then(response=>{
        this.loadingForm = false
        let data = response.data
        this.form.penjelasan = data.data.keterangan_penjelasan
        this.form.kegiatan = data.data.kegiatan
      }).catch(error=>{
        this.loadingForm = false
      })

      this.form.kategori = item.kategori
      this.form.satuan_output = item.satuan_output
      this.form.nama_aktifitas = item.nama_aktifitas
      this.form.rumpun_id = item.rumpun_id !== undefined ? item.rumpun_id : null
      this.form.angka_kredit = item.angka_kredit !== undefined ? item.angka_kredit : null
      this.form.level = item.level !== undefined ? item.level : null

      if (!item.id){
        // buat laporan baru
        this.form.button='Simpan'
      } else {

        this.form = {...this.form, ...item}
        console.log(this.form)
        this.form.sasaran_kinerja_id = item.jenis == 'kinerja' ? item.sasaran_kinerja_id : item.sasaran_kinerja_tambahan_id
        // edit laporan
        this.form.button='Ubah'
      }

      this.dialog = true

    },

    closeDialog(){
      this.valid = true
      this.form = {}
      this.form={
        nama_aktifitas:'',
        penjelasan:'',
        sasaran_kinerja_id:'',
        volume:'',
        jam_mulai:7,
        menit_mulai:0,
        jam_selesai:7,
        menit_selesai:0,
        keterangan:'',
        satuan_output:'',
        kegiatan:[],
        angka_kredit:null,
        level:'',
        rumpun_id:null
      }
    },

    getAktivitasTampil(jenis){
      let d = this.selectedDate ? this.selectedDate : moment().format('YYYY-MM-DD');

      if(jenis == 'kinerja'){
        this.loadingUtama = true
      }else if(jenis == 'umum'){
        this.loadingTambahan = true
      }

      PelaksanaService.getAktivitasTampil({tanggal: d, jenis: jenis}).then(response => {
        if (jenis == 'kinerja'){
          this.loadingUtama = false
          this.laporanUtama = response.data.data
        }else if (jenis == 'umum'){
          this.loadingTambahan = false
          this.laporanTambahan = response.data.data
        }

      })
    },

    getAktivitasUtama(){
      PelaksanaService.inputAktivitasGetDataUtama().then(response=>{
        this.itemsAU = response.data.data
      })
    },

    getAktivitasTambahan(){
      PelaksanaService.inputAktivitasGetDataTambahan().then(response=>{
        this.itemsTmb = response.data.rumpun_id != 13 ? response.data.data : []
        // this.itemsTmb = response.data.data
      })
    },

    compareDate(a, b) {
      let dateA = new Date(a)
      let dateB = b ? new Date(b) : new Date()
      dateA.setHours(0, 0, 0, 0)
      dateB.setHours(0, 0, 0, 0)
      if (dateA.getTime() > dateB.getTime()) {
        return 1
      } else if (dateA.getTime() < dateB.getTime()) {
        return -1
      }
      return 0
    },

    onPasteKeterangan(){
      store.commit('snackbar/setSnack',{message:'Mohon maaf tidak bisa copy-paste. :)', color:'warning', centered:true})
      this.preventDefault()
    }

  },
}
</script>

<style lang="scss" scoped>
  .active.v-icon.mdi-chevron-down{
    transform: rotate(-180deg);
  }
</style>
